import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, take } from 'rxjs';
import { LocalAWSCart, SourceType } from 'src/app/Models/cart.models';
import { SignInResponse } from 'src/app/Models/SignInResponse';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { Customer } from 'src/app/shared/models/customer';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss'
})

export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {
  isFormValid: boolean;
  passwordValue: string;
  emailValue: string;
  customer: Customer;
  protected _onDestroy = new Subject<void>();


  constructor(
    private _toastService: ToastService,
    private _router: Router,
    private _apiService: ApiService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _customerService: CustomerService,
    private _loaderService: LoaderService
  ) { }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.performSignIn();
    }
  }

  ngOnInit(): void {
    this._customerService.customer.pipe(take(1)).subscribe(customer => {
      this.customer = customer;

      if(customer != undefined && customer.isLoggedIn()) {
        // user is already logged in redirect to home
        this._router.navigate(['/home']);
      }
    });
    this.emailValue = this.customer?.email;
  }

  public performSignIn(): void {
    if (this.isFormValid === true) {
      this._loaderService.requestShowLoader();
      this.checkRegistration();
    } else {
      this._loaderService.requestHideLoader();
      this._googleAnalyticsService.sendVPV('sign-in/error/email-or-password-are-incorrect');
      this._toastService.triggerErrorToast('Please check email or password');
    }
  }

  // before the user signs in - lets make sure their email is registered
  public checkRegistration(): void {
    this._loaderService.requestShowLoader();
    const currentEmail: string = this.emailValue;

    this._apiService.isRegistered(currentEmail).pipe(take(1)).subscribe({
      next: resp => {
        this._customerService.updateRegistrationStatus(resp);
        if (resp == 'NotRegistered') {
          this._customerService.updateEmail(currentEmail);
          this._loaderService.requestHideLoader();
          this._router.navigate(['checkout/create-profile']);
        }
        else if (resp == 'Registered') {
          this._customerService.updateEmail(currentEmail);
          this.actualSignIn();
        }
        else if (resp == 'Pending') {
          this._customerService.updateEmail(currentEmail);
          this._loaderService.requestHideLoader();
          this._router.navigate(['checkout/verify-email']);
        }
        else {
          this._loaderService.requestHideLoader();
          this._toastService.triggerErrorToast('Error verifying the provided email.');
        }
      },
      error: error => {
        console.log(error);
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Network Error via Sign In. Please try again.');
      },
    });
  }

  actualSignIn(): void {
    this._apiService.signInCustomer(this.emailValue, this.passwordValue).pipe(take(1)).subscribe({
      next: response => {
        this.handleLoginResponse(response);
      },
      error: _ => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Email or password are incorrect');
      }
    });
  }

  handleLoginResponse(response: SignInResponse): void {
    const customer: Customer = this.customer ?? new Customer();
    customer.email = response.EmailLoginId;
    customer.businessPartnerID = response.BpID;
    customer.addressNumber = response.addressNumber;
    customer.transferResponse = response.transferData.transfer_response;
    this._customerService.updateCustomer(customer);
    this.updateLocalCartIfNeeded(response, customer);
  }

  updateLocalCartIfNeeded(response:SignInResponse, customer:Customer): void {

    console.log('getting the local cart')
    this._apiService.getLocalAWSCart(response.EmailLoginId).pipe(take(1)).subscribe({
      next: cartResponse => {
        console.log('next')
        const request: LocalAWSCart = cartResponse;

        // If they created a profile (de-one) -> update the SAP cart if they have the business partner id and send to speed pay
        if (cartResponse.source_id == SourceType.DEONE) {
          // Update the AWS cart with the business partner ID if not already there.
          if (request.business_partner_id == undefined && !request.opened_in_sap) {
            console.log('Found local cart not opened in SAP - updating businessPartnerId');
            request.business_partner_id = response.BpID;
            this._apiService.updateAWSLocalCartWithRequest(request).pipe(take(1)).subscribe(updateResponse => {
              this._loaderService.requestHideLoader();
              this._router.navigate(['checkout/transfer']);
              console.log(updateResponse);
            });
          } else {
            //else the business partner id exists so update the SAP cart 
            this.updateSAPCart();
          }
        } else {
          // If they didn't create a profile -> send to transfer profile 
          this._loaderService.requestHideLoader();
          this._router.navigate(['checkout/transfer']);
        }

      },
      error: error => {
        console.log('error')
        console.log(error);
        this._loaderService.requestHideLoader();
        this._router.navigate(['checkout/transfer']);
      },
    });
  }

  updateSAPCart(): void {
    this._apiService.updateSAPCart(this.customer, SourceType.MyAccount).pipe(take(1)).subscribe({
      next: cartResponse => {
        this._loaderService.requestHideLoader();
        const failed = cartResponse.filter((item) => item.success === false);

        if(failed.length === 0) {
          this._router.navigate(['checkout/speed-pay']);
        } else {
          for (const item of failed) {
            this._toastService.triggerErrorToast(item.message);
          }
        }
      },
      error: err => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Unknown Error Transfering Cart...');
        console.error(err);
      }
    });
  }

  ngAfterViewInit(): void {
    this._googleAnalyticsService.sendVPV('sign-in/load/sign-in');
  }

  public updateUserEmail($event): void {
    this.emailValue = $event;
  }

  public updateUserPassword($event): void {
    this.passwordValue = $event;
  }

  public updateFormValidation($event): void {
    this.isFormValid = $event;
  }

  public goBack(): void {
    this._googleAnalyticsService.sendVPV('sign-in/load/sign-in');
    this._router.navigate(['checkout/home']);
  }

  public goto(url: string): void {
    window.open(url, '_blank');
  }

  public register(): void {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/register');
    window.open('https://p-auth.duke-energy.com/my-account/registration-r5');
  }

  public needHelpClick() {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/na/need-help-signing-in');
    window.open('https://www.duke-energy.com/need-help-sign-in', '_blank');
  }

  public forgotUsernameClick(_) {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/forgot-username');
    window.open('https://www.duke-energy.com/forgot-username', '_blank');
  }

  public forgotPasswordClick(_) {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/forgot-password');
    window.open('https://p-auth.duke-energy.com/my-account/sign-in/forgot-password', '_blank');
  }

  // TODO: still in need of?
  public eventClick(event) {
    if ((event.target.innerHTML).indexOf('help') >= 0) {
      //  this._googleAnalyticsService.sendVPV('sign-in/clickthrough/na/need-help-signing-in');
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
