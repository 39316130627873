import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSource } from 'src/app/Models/DataSource';
import { FAQ, HomeContent } from 'src/app/Models/HomeContent';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  public homeContent: HomeContent = DataSource.homeContent();
  public faqs: FAQ[] = [];

  constructor(
    private _router: Router,
  ) {

  }

  ngOnInit(): void {
    this.faqs = this.homeContent.faqs
  }

  clickOnPlan(plan): void {
    this._router.navigate(['tier'], { queryParams: { plan: plan.id } });
  }
}
