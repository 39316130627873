<div *ngIf="plan" class="w-full flex flex-col md:flex-row px-48">
    <div class="w-full max-w-[250px] md:min-w-[555px] md:max-w-[555px]">
        <img class="min-w-full" src="{{plan.hero.image}}">
    </div>
    <div class="w-full md:w-full pl-0 md:pl-24">
        <div class="flex flex-col items-start mt-24 md:mt-[-8px]">
            <div class="flex flex-col items-start text-left">
                <h2 class="text-2xl md:text-4xl text-dukeDarkBlue pb-20 w-full">{{plan.content.planTile.name}}</h2>
                <p class="pb-20" id="description" [innerHTML]="plan.content.planTile.description"></p>
            </div>
            <p id="font-bold" class="text-xl pb-12 text-gray-darker">Select a plan</p>
            <div *ngFor="let plan of plan.content.tiers; index as i"
                class="flex-1 flex items-center justify-left h-full w-full clickable hover:cursor-pointer pb-12"
                (click)="clickOnActionButton(i)">
                <div [ngClass]="selectedItem === i ? 'border-linkBlue' : 'border-gray'"
                class="flex flex-row md:flex-row text-left w-full border-2 rounded-md  p-12">
                    <div class="flex-col w-[100%]">
                        <div class="text-xl font-[500]">{{plan.name}}</div>
                        <div class="text-base mt-10 mb-20" [innerHTML]="plan.description">
                        </div>
                        <div>
                            <span class="font-[500] text-customGreyBold">Cost:</span>
                            ${{plan.cost}} per month
                        </div>
                    </div>
                    <div [ngClass]="selectedItem === i ? 'bg-teal-darker ring-0' : 'bg-white ring-2 ring-gray'"
                        class="flex flex-shrink-0 m-12 p-4 w-24 h-24 rounded-full transition-colors duration-800"
                        aria-hidden="true">
                        <span
                            [ngClass]="selectedItem === i ? 'transition-all duration-800 ease-out w-16' : 'transition-all duration-800 ease-out w-0'"
                            class="h-16 overflow-hidden">
                            <img class="p-px w-16 h-16 text-white fill-current" src="/assets/icons/check-base.svg"
                                viewBox="0 0 64 64" width="16" height="16" focusable="false" aria-hidden="true">
                        </span>
                    </div>
                </div>
            </div>
            <div class="pb-36 justify-start sm:w-320">
                <section class="mt-12">
                    <eds-button-group [primaryText]="'Add Plan'" [secondaryText]="'Previous'" (primaryClick)="addPlan()"
                        (secondaryClick)="goPrevious()">
                    </eds-button-group>
                </section>
            </div>
        </div>
    </div>
</div>
