import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, from, Observable, throwError } from 'rxjs';
import { AppConfig } from '../config/app.config';
import { ApiService } from './api.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  // retrieves the static token that ships with the app
  private get staticJWTToken():string {
    return this.appConfig.getConfig('accessToken');
  }

  // retrieves the session token given to the app for the session
  private get sessionJWTToken():string {
    return sessionStorage.getItem('session_access_token');
  }

  /// checks to see if we have a valid session token
  private get _hasJWTSessionToken():boolean {
    const hasToken:boolean = this.sessionJWTToken != undefined;
    return hasToken;
  }

  constructor(
    private appConfig: AppConfig,
    private _apiService: ApiService) {
  }

  // redid this intercept - it now identifies the first api call and will get you a token when it does
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if not an api call or the call for the auth token - handle straight away
    const apiURL = this.appConfig.getConfig("apiBaseUrl");
    const parsedUrl = new URL(apiURL);
    const domain =  parsedUrl.hostname;
    if (request.url.includes('auth/token') || !request.url.includes(domain)) {
      const authReq = request.clone({ setHeaders: { Authorization: 'Bearer ' + this.staticJWTToken } });
      return next.handle(authReq);
    }

    // else - check for if the JWT token has already been retrieved
    if (this._hasJWTSessionToken) {
      try {
        const authReq = request.clone({ setHeaders: { Authorization: 'Bearer ' + this.sessionJWTToken } });
        return next.handle(authReq);
      } catch (exception) { }
    } else {
      // if it isn't the first time - go ahead and get the token
      return this.refreshTokenMethod(request, next);
    }
  }



  refreshTokenMethod(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // get the token first 
    return from(this._apiService.getNewToken()).pipe(
      switchMap((_: any) => {
        // successful response means we have the token 
        // now pass back the original api call with the new token in place.  
        // Note - the getnewtoken func in the api service sets the access token for us.  We could revist this down the road
        request = request.clone({ setHeaders: { Authorization: 'Bearer ' + this.sessionJWTToken } });
        return next.handle(request);
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}