import { HomeContent, PlanTile } from './HomeContent';
import homeData from 'src/content/home.json';
import planData from 'src/content/plans.json';
import { Plan, PlanContent, Tier } from './PlansContent';
import { Detail, PlanContainer } from './PlanDetails';
import surgeDetailsData from 'src/content/surge-details.json';
import wiringDetailsData from 'src/content/wiring-details.json';
import heaterDetailsData from 'src/content/heater-details.json';

export class DataSource {
    public static homeContent(): HomeContent {
        return homeData;
    }

    public static plansContent(): PlanContent {
        return planData;
    }

    public static specificPlan(planId: string): Plan {
      return this.plansContent().plans.find(x => x.id == planId ||
         x.content.tiers.find(t => t.id == planId));
    }

    // public static basicPlanInfo(planId: string): PlanTile {
    //   return this.homeContent().plan
    // }

    public static planContainer(planID:string): PlanContainer {
        switch (planID) {
            case 'surge-plans':
              return surgeDetailsData;
            case 'home-wiring-repair-plans':
              return wiringDetailsData
            case 'water-heater-repair-plans':
              return heaterDetailsData;
            default:
              return null;
          }
    }

    public static planDetails(container:PlanContainer, tierID:string): Detail {
        const detail:Detail = container.details.find((detail) => detail.id === tierID);
        return detail;
    }

    public static getPlanDetailByID(id:string): Detail {
      const all: string[] = ['surge-plans', 'home-wiring-repair-plans', 'water-heater-repair-plans'];
      for (const planID of all) {
        const container = this.planContainer(planID);
        const detail: Detail = container.details.find( current => current.id.toUpperCase() == id.toUpperCase());
        if (detail) {
          return detail
        }
      }

      return null
  }

  public static getTier(planID:string, tierID:string):Tier {
     const plan:Plan = DataSource.specificPlan(planID);
     const tier:Tier = plan.content.tiers.find((element) => element.id == tierID);
     return tier;
  }

  public static getPlanTile(planID:string): PlanTile {
    return DataSource.homeContent().plans[0].find(x => x.id == planID);
 }
}