import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CustomerService } from './services/customer.service';
import { take } from 'rxjs';
import { Customer } from './shared/models/customer';

@Injectable({
  providedIn: 'root'
})
export class loginCheckGuard implements CanActivate {

  constructor(private _customerService:CustomerService, private router: Router) {
  }

  canActivate(): boolean {
    const customer:Customer = this._customerService.reloadCustomer();

    if (customer == undefined || !customer.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/checkout/transfer']);
      return false;
    }
  }
}