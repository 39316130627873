<div class="pb-48 px-24">
    <div class="flex flex-col items-center px-48 pt-48">
        <div class="flex flex-col items-center max-w-md text-center">
            <h2 class="text-3xl md:text-4xl pb-20 text-dukeDarkBlue Roboto">Home Protection Plans</h2>
            <p>Select one or more plan(s) you're interested enrolling in.
            </p>
        </div>
    </div>

    <div class="flex flex-wrap justify-around">
        <div *ngFor="let plan of homeContent.plans[0]" class="lg:w-[45%] content-stretch mx-4 mt-32 sm:h-[192px]">
            <app-simple-card (click)="clickOnPlan(plan)" class="hover:cursor-pointer w-full" [cardTitle]="plan.name" [description]="plan.description" [cardIcon]="plan.image">
            </app-simple-card>
        </div>
        <!-- standardize width and height -->
         <!-- send over for 1 plan -->
</div>
