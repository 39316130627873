import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSource } from 'src/app/Models/DataSource';
import { HomeContent, PlanTile } from 'src/app/Models/HomeContent';
import { Plan, PlanContent, Tier } from 'src/app/Models/PlansContent';
import { SharedModule } from 'src/app/shared/shared.module';
import { Customer } from 'src/app/shared/models/customer';
import { ToastService } from 'src/app/services/toast.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Subject, takeUntil } from 'rxjs';
import { ModalMessageComponent } from 'src/app/components/modal-message/modal-message.component';


@Component({
  selector: 'app-shopping-cart',
  standalone: true,
  imports: [SharedModule, ModalMessageComponent],
  templateUrl: './shopping-cart.component.html',
  styleUrl: './shopping-cart.component.scss'
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  public homeContent: HomeContent = DataSource.homeContent();
  public plansContent: PlanContent = DataSource.plansContent();
  plan: Plan;
  public selectedItem = -1;
  public removedTiers: any[] = [];
  public tiers: Tier[] = [];
  public customer: Customer;
  public isTooltipClicked = false;
  public items: PlanTile[] = [];
  public plansInTotal: any[] = [];
  protected _onDestroy = new Subject<void>();

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _toastService: ToastService,
    private _customerService: CustomerService
  ) {

  }

  public get subTotal(): number {
    return this.tiers.map((tier) => Number(tier.cost)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
 

  public get monthlyCost(): number {
    return this.subTotal;
   }

  public toggleTooltip(): void {
   this.isTooltipClicked = !this.isTooltipClicked;
  } 

  public closeTooltip(): void {
    this.isTooltipClicked = false;
  }
  

  ngOnInit(): void {
    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
      this.customer = cust;
      // TODO: change the concat to something better
      this.tiers = [];
      this.removedTiers = [];
      this.plansContent.plans.forEach(item => {
        this.tiers = this.tiers.concat(item.content.tiers.filter(plan => this.customer?.addedPlans?.includes(plan.id) ));
      });
     
    });
    
  }

//This function calls the removePlan method for customerService
  public remove(id: any) {
   if( this._customerService.removePlan(id) !== null){
     this.removedTiers.push(id);
   }}
 
 
  changePlan(id: any) {
    const changes = this.tiers.find(tiers => tiers.id === id);
    if (changes.id == 'HW' && 'HWD'){
      this._router.navigateByUrl('/tier?plan=home-wiring-repair-plans');
    } else if(changes.id == 'SCES' && 'SCEN' && 'SCP'){
      this._router.navigateByUrl('/tier?plan=surge-plans');
    } else {
      this._router.navigateByUrl('/tier?plan=water-heater-repair-plans');
    }
  }
    

  

  continue(): void {
    this._router.navigate(['home']);
  }

  checkout(): void {
    if(this.tiers.length < 1){
      this._toastService.triggerErrorToast('Your cart is empty');
    }
    else if(this.customer != undefined && this.customer.isLoggedIn()) {
      /// user is already signed in go to the transfer page
      this._router.navigate(['checkout/transfer']); 

    } else {
        // user has not signed in yet show the login screen options
        this._router.navigate(['checkout/home']);
    }
  }

  routeToPlan(url: string): void {
   this._router.navigateByUrl(url);
  }

  onClickTCLink() {
    const url =
      'https://www.duke-energy.com/-/media/pdfs/unindexed/231873-8-terms-and-conditions-my-energy-bill.pdf?rev=010eacb1babb47239de6ca832db6ce4d';
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
