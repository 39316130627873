export enum SourceType {
  DEONE = 'de-one',
  MyAccount = 'my-account'
}

export interface LocalAWSCart {
  opened_in_sap:       boolean;
  zip:                 number;
  is_residential:      boolean;
  address:             string;
  business_partner_id: string;
  email:               string;
  address_2:           string;
  state:               string;
  city:                string;
  sap_cart_id:         null;
  date:                Date;
  products:            string[];
  property_type:       string;
  own_or_rent:         string;
  source_id:           SourceType;
}


export interface SAPCartRequest {
  address_number:   string;
  cart:             LocalAWSCart;
}


export interface SAPCartResponse {
  product:string;
  success:boolean;
  cartID: string;
  message:string;
}