/* eslint-disable @typescript-eslint/member-ordering */
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { EdsButtonComponent, EdsDrawerComponent, EdsHorizontalDividerComponent, EdsSvgComponent } from '@electronds/ng-components-duke';
import { Subject, takeUntil } from 'rxjs';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { TabDropdownComponent } from '../tab-dropdown/tab-dropdown.component';
import { CustomerService } from 'src/app/services/customer.service';
import { FormsModule } from '@angular/forms';
import { Plan, PlanContent, Tier } from 'src/app/Models/PlansContent';
import { HomeContent } from 'src/app/Models/HomeContent';
import { DataSource } from 'src/app/Models/DataSource';
import { Customer } from 'src/app/shared/models/customer';


export interface EdsNavGroup {
  label: string;
  icon: CustomSvg;
  children: EdsNavLink[][];
  expanded?: boolean;
  url?: string
}

export interface EdsNavLink {
  label: string;
  url: string;
}

export interface EdsActionButton {
  icon: CustomSvg;
  label: string;
  action?: (actionLabel?: string) => void;
}

export interface CustomSvg {
  path: string;
  attributes: EdsSvgAttributes;
  sizeClass: string;
}

export interface EdsSvgAttributes {
  [attribute: string]: string;
}

@Component({
  selector: 'app-one-header',
  standalone: true,
  imports:[EdsButtonComponent, EdsSvgComponent, EdsDrawerComponent, EdsHorizontalDividerComponent,RouterModule, NgClass, NgIf, NgFor, TabDropdownComponent, FormsModule],
  templateUrl: './one-header.component.html',
  styleUrls: ['./one-header.component.scss']
})

export class OneHeaderComponent<T> implements OnInit, OnDestroy{
@Input() isHamburgerMenuOpen = false;
@Input() navGroups: EdsNavGroup[] = [];
@Input() currentNavGroup!: EdsNavGroup;
@Input() navDrawerBody: TemplateRef<T> | undefined = undefined;
@Input() hamburgerMenuTitle = 'Navigation';
@Input() allowScrollAnimation: boolean = true;
@Input() actionButtons: EdsActionButton[] = [];
@Input() headerIcon?: CustomSvg;
@Input() plan: Plan;
@Output() actionButtonPress = new EventEmitter<EdsActionButton>();
public siteTitle = 'Home Protection Plans';
public selectedItem = 1;
public homeContent: HomeContent = DataSource.homeContent();
public plansContent: PlanContent = DataSource.plansContent();
public tiers: Tier[] = [];
public customer: Customer;
public plansCount: number = 0;

protected _onDestroy = new Subject<void>();
  protected justScrolledDown = false;
  protected hasScrolledDown = false;
  protected lastScrollYPos: number | undefined;
  public isUserFullyAuthenticated = false
  public hasLocation:boolean = false

  constructor(
    private _customerService: CustomerService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
    this.customer = cust;
    this.hasLocation = (cust != undefined && cust.hasValidLocation());
    this.isUserFullyAuthenticated = (cust != undefined && cust.isLoggedIn());
    this.plansCount = cust?.addedPlans?.length;
      // TODO: we should need this for the sign out vs sign in
    });
  }


  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  public toggleHamburgerMenuOpen(): void {
    this.isHamburgerMenuOpen = !this.isHamburgerMenuOpen;
  }

  public toggleNavGroup(navGroup: EdsNavGroup): void {
    const wasHiddenBefore = !navGroup.expanded;
    for (const group of this.navGroups) {
      group.expanded = false;
    }
    if (wasHiddenBefore) {
      navGroup.expanded = true;
    }
  }


public actionButtonPressed(actionButton: EdsActionButton): void {
  if (actionButton.action) {
    actionButton.action(actionButton.label);
  }
  this.actionButtonPress.emit(actionButton);
}

public headerNavGroups: EdsNavGroup[] = [
  {
    label: 'All Plans',
    icon: {
      path: 'assets/icons/shield-base.svg',
      sizeClass: 'icon-24',
      attributes: {
        fill: '#00789e'
      },
    },
    children: [
      [
        {
          label: 'Home Wiring Repair',
          url: 'tier?plan=homeWiringRepair'
        },
        {
          label: 'Surge Coverage & Grounding',
          url: 'tier?plan=surgeCoverageGrounding'
        },
        {
          label: 'Water Heater Repair',
          url: 'tier?plan=waterHeaterRepair'
        }
      ]
    ],
  }
];



}
