import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer, Phone, TypeOfProperty } from '../shared/models/customer';
import { AddressResult } from '../address-search/address-search.component';
import { DataSource } from 'src/app/Models/DataSource';
import { Tier } from '../Models/PlansContent';
import { LocalAWSCart } from '../Models/cart.models';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public tiers: Tier[] = [];
  private _customer$ = new BehaviorSubject<Customer>(null);
  private _customer = new Customer();
  public removedTiers: string[] = [];

  constructor() {
    const customer = this.storedUser;
    if(customer != undefined) {
      this._customer =   customer;
      this.broadcastAccountChanges();
    }
   }

  public resetCustomer(): void {
    this._customer = new Customer();
    this.broadcastAccountChanges();
  }

  get customer(): Observable<Customer> {
    return this._customer$.asObservable();
  }

  get isFullyAuthenticated(): boolean {
    return this._customer.fullyAuthenticated
  }

  private get storedUser():Customer {
    const raw = sessionStorage.getItem('customer');
     return new Customer(JSON.parse(raw));
  }

  public reloadCustomer():Customer {
      /// reloads the user from the session storage
    this._customer = this.storedUser;
    this.broadcastAccountChanges();
    return this._customer;
  }


  updateEligibility(address: AddressResult, isResidential: boolean, typeOfProperty: TypeOfProperty, isOwner: boolean): void {
    this._customer.address = address;
    this._customer.isResidential = isResidential;
    this._customer.typeOfProperty = typeOfProperty;
    this._customer.isOwner = isOwner;
    this.broadcastAccountChanges();
  }

  public updateIsFullyAuthenticated(isFullAuth: boolean): void {
    this._customer.fullyAuthenticated = isFullAuth;
    this.broadcastAccountChanges();
  }

  public updateEmail(email: string): void {
    this._customer.email = email;
    this.broadcastAccountChanges();
  }

  public updateRegistrationStatus(status: string): void {
    this._customer.registration = status;
    this.broadcastAccountChanges();
  }

  public updateName(firstName: string, lastName): void {
    this._customer.firstName = firstName;
    this._customer.lastName = lastName;
    this.broadcastAccountChanges();
  }

  public updatePassword(password: string): void {
    this._customer.password = password;
    this.broadcastAccountChanges();
  }

  public updatePhone(phone: Phone): void {
    this._customer.phone = phone;
    this.broadcastAccountChanges();
  }

  public updateCustomer(customer: Customer): void {
    this._customer = customer;
    this.broadcastAccountChanges();
  }

  public addPlan(planId: string): string[] {
    // TODO: maybe a check for the correct ID here to be double safe? 
    if (planId && !this._customer?.addedPlans?.includes(planId)) {
      this._customer?.addedPlans?.push(planId);
      this.broadcastAccountChanges();
    }
    return this._customer.addedPlans;
  }

  public addContent(content: LocalAWSCart) {
     // pre-populate the card with the AWS content
     for(const productID of content.products ) {
      this.addPlan(productID);
    }

    const convertedEnum: TypeOfProperty = TypeOfProperty[Object.keys(TypeOfProperty).find(key => TypeOfProperty[key] === content.property_type)];

    this._customer.address =  {street: content.address, apartment: content.address_2, city: content.city, state: content.state, zipCode: content.zip.toString()};
    this._customer.isOwner = content.own_or_rent == 'Own'
    this._customer.isResidential = content.is_residential;
    this._customer.typeOfProperty = convertedEnum;
    this._customer.businessPartnerID = content.business_partner_id;
    this._customer.email = content.email;
    this.broadcastAccountChanges();
  }

  // this could now be redundant of the func above since we are using  the abbreivated id now
  public addPlanByid(id: string): string[] {
    const plan = DataSource.getPlanDetailByID(id);
    return this.addPlan(plan?.id ?? null)
  }

  public removePlan(id: string){
    const index = this._customer?.addedPlans?.indexOf(id);
    if (index !== -1) {
      this._customer.removedPlans.push(id);
      this._customer?.addedPlans?.splice(index, 1);
      this.broadcastAccountChanges();
    }
    return this._customer?.addedPlans.length - 1
  }

 

  private broadcastAccountChanges(): void {
    sessionStorage.setItem('customer', JSON.stringify(this._customer));
    this._customer$.next(this._customer);
  }
}
